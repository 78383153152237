
import React from 'react'
import { BrowserRouter as Router, Route, Switch, Link, Redirect, NavLink } from 'react-router-dom'

import {
	Alignment,
	Button,
	Classes,
	Navbar,
	NavbarDivider,
	NavbarGroup,
	NavbarHeading,
	Icon,
	Intent
} from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

import { Dashboards } from './views/dashboards'
import { EditDashboard } from './views/edit-dashboard'
import { Login } from './views/login'

import API from './api'

export default class App extends React.Component {

	constructor(props) {
		super(props)
		App.shared = this
	}

	componentDidMount() {
		API.shared.addEventListener(this, API.Events.LoginStateChanged, () => {
			this.forceUpdate()
		})
		API.shared.addEventListener(this, API.Events.ConnectionStateChanged, () => {
			this.forceUpdate()
		})
	}

	componentWillUnmount() {
		API.shared.removeEventListener(this)
	}

	render() {

		let blockingMessage = null

		if(API.shared.connectionState === API.ConnectionStates.Offline) {
			blockingMessage = 'Cloud is currently offline'
		} else if(API.shared.connectionState === API.ConnectionStates.Connecting) {
			blockingMessage = 'Connecting...'
		} else if(API.shared.connectionState === API.ConnectionStates.Authenticating) {
			blockingMessage = 'Authenticating...'
		} else if(API.shared.connectionState === API.ConnectionStates.Online) {
			if(!API.shared.isLoggedIn) {
				return (<Login/>)
			}
		}

		if(blockingMessage) {
			return (
				<div id="BlockingMessage">
					<div className="Content">
						{ blockingMessage }
					</div>
				</div>
			)
		}

		return (
			<Router>
				<React.Fragment>
					<Navbar>
						<NavbarGroup align={ Alignment.LEFT }>
							<NavbarHeading>
								<Link to="/">
									<Icon icon={ IconNames.CLOUD } iconSize={ Icon.SIZE_LARGE } intent={ Intent.PRIMARY }/>
									&nbsp;&nbsp;
									Buttons.app Cloud
								</Link>
							</NavbarHeading>
							<NavbarDivider />
							<NavLink to="/dashboards">
								<Button className={ Classes.MINIMAL } icon="widget-button" text="Dashboards"/>
							</NavLink>
						</NavbarGroup>
						<NavbarGroup align={ Alignment.RIGHT }>
							<NavbarHeading>
								<Icon icon={ IconNames.USER } iconSize={ Icon.SIZE_LARGE } intent={ Intent.PRIMARY }/>
								&nbsp;&nbsp;
								{ API.shared.user.email }
							</NavbarHeading>
							<Button className={ Classes.MINIMAL } icon="log-out" text="Logout" onClick={ _ => API.shared.logout() }/>
						</NavbarGroup>
					</Navbar>
					<Switch>
						<Route exact path="/" render={ () =>
							<Redirect to="/dashboards"/>
						}/>
						<Route exact path="/dashboards" component={ Dashboards }/>
						<Route exact path="/dashboards/:id" component={ EditDashboard }/>
						<Route render={ () =>
							<div>Page not found!</div>
						}/>
					</Switch>
				</React.Fragment>
			</Router>
		)
	}

}
