
class ButtonsCloud {

	constructor() {
		this.rqCallbacks = {}
		this.rqNextCallbackId = 1

		this.eventCallbacks = []

		this.connect()
		console.log('ButtonsCloud:constructor')
	}

	addEventListener(listener, event, callback) {
		this.eventCallbacks.push({ listener, event, callback })
	}

	removeEventListener(listener) {
		this.eventCallbacks = this.eventCallbacks.filter(ec => (ec.listener !== listener))
	}

	triggerEvent(event, data) {
		for (const ec of this.eventCallbacks) {
			if (ec.event == event) {
				ec.callback(data)
			}
		}
	}

	connect() {
		if (this.socket) {
			this.socket.close()
			this.socket.onopen = null
			this.socket.onmessage = null
			this.socket.onerror = null
			this.socket.onclose = null
			this.socket = null
		}
		this.connected = false
		let cloudAddress = 'wss://cloud.buttons.app'
		if (document.location.hostname === 'localhost') {
			cloudAddress = 'ws://localhost:11030'
		}
		this.socket = new WebSocket(cloudAddress)
		this.socket.onopen = _ => this.onConnect()
		this.socket.onmessage = event => this.onMessage(event)
		this.socket.onerror = event => this.onError(event)
		this.socket.onclose = event => this.onClose(event)
	}

	sendMessage(msg) {
		console.log('ButtonsCloud:sendMessage', msg)
		this.socket.send(JSON.stringify(msg))
	}

	sendRequest(rq, args, callback) {
		if (typeof (args) !== 'object') {
			args = {}
		}
		args = { rq, ...args }
		if (callback) {
			args.callbackId = this.rqNextCallbackId++
			this.rqCallbacks[args.callbackId] = callback
		}
		this.sendMessage(args)
	}

	onConnect() {
		console.log('ButtonsCloud:onConnect')
		if (this.reconnectTimer) {
			clearTimeout(this.reconnectTimer)
			this.reconnectTimer = null
		}
		this.connected = true
		this.sendMessage({ rq: 'INIT' })
		this.triggerEvent(ButtonsCloud.Events.Connected)
	}

	onMessage(event) {
		const obj = JSON.parse(event.data)
		console.log('ButtonsCloud:onMessage', obj)
		if (obj.rq == 'RESPONSE' && obj.callbackId && this.rqCallbacks[obj.callbackId]) {
			this.rqCallbacks[obj.callbackId](obj.response)
			delete this.rqCallbacks[obj.callbackId]
		}
		this.triggerEvent(ButtonsCloud.Events.MessageReceived, obj)
	}

	onError(event) {
		console.log('ButtonsCloud:onError')
		this.socket.close()
	}

	onClose(event) {
		if (this.connected) {
			console.log('ButtonsCloud:onClose')
			this.connected = false
			this.socket.close()
			this.triggerEvent(ButtonsCloud.Events.Disconnected)
		}
		if (this.reconnectTimer) {
			clearTimeout(this.reconnectTimer)
		}
		console.log('creating timer')
		this.reconnectTimer = setTimeout(() => {
			this.connect()
		}, 5000)
	}

}

ButtonsCloud.Events = {
	Connected: 1,
	MessageReceived: 2,
	Disconnected: 3
}

// ======================================================

export default class API {

	static get shared() {
		if (!API.sharedAPIInstance) {
			API.sharedAPIInstance = new API()
		}
		return API.sharedAPIInstance
	}

	constructor() {
		console.log('API:Constructor!')
		this.connectionState = API.ConnectionStates.Connecting
		this.cloud = new ButtonsCloud()
		this.clearState()

		this.eventCallbacks = []

		this.cloud.addEventListener(this, ButtonsCloud.Events.Connected, () => this.onCloudConnected())
		this.cloud.addEventListener(this, ButtonsCloud.Events.Disconnected, () => this.onCloudDisconnected())

		this.cloud.addEventListener(this, ButtonsCloud.Events.MessageReceived, msg => {
			if (msg.rq == 'DASHBOARD_EVENT') {
				const dashboard = msg.dashboard
				if (msg.type == 'CREATE') {
					this.dashboards.push(dashboard)
				} else if (msg.type == 'UPDATE' || msg.type == 'ADD-AGENT' || msg.type == 'REMOVE-AGENT') {
					for (const idx in this.dashboards) {
						if (this.dashboards[idx].id == dashboard.id) {
							for (var k in dashboard) {
								this.dashboards[idx][k] = dashboard[k]
							}
							break
						}
					}
				} else if (msg.type == 'DELETE') {
					for (const idx in this.dashboards) {
						if (this.dashboards[idx].id == dashboard.id) {
							this.dashboards.splice(idx, 1)
							break
						}
					}
				} else {
					console.log('unknown dashboard event', msg.type)
					return
				}
				this.triggerEvent(API.Events.DashboardsUpdated)
			}
		})
	}

	setConnectionState(newState) {
		if (newState != this.connectionState) {
			this.connectionState = newState
			this.triggerEvent(API.Events.ConnectionStateChanged)
		}
	}

	clearState() {
		this.isLoggedIn = false
		this.user = null
		this.dashboards = []
	}

	onCloudConnected() {
		const sessionKey = localStorage.getItem('sessionKey')
		if (sessionKey) {
			this.setConnectionState(API.ConnectionStates.Authenticating)
			this.sendRequest('AUTHENTICATE', { sessionKey }, response => {
				this.processAuthReponse(response)
				this.setConnectionState(API.ConnectionStates.Online)
			})
		} else {
			this.setConnectionState(API.ConnectionStates.Online)
		}
	}

	onCloudDisconnected() {
		this.setConnectionState(API.ConnectionStates.Offline)
	}

	addEventListener(listener, event, callback) {
		this.eventCallbacks.push({ listener, event, callback })
	}

	removeEventListener(listener) {
		this.eventCallbacks = this.eventCallbacks.filter(ec => (ec.listener !== listener))
	}

	triggerEvent(event, data) {
		for (const ec of this.eventCallbacks) {
			if (ec.event == event) {
				ec.callback(data)
			}
		}
	}

	sendRequest(rq, args, callback) {
		return this.cloud.sendRequest(rq, args, callback)
	}

	afterAuth() {
		API.shared.sendRequest('GET-DASHBOARDS', {}, response => {
			this.dashboards = response.dashboards
			this.triggerEvent(API.Events.DashboardsUpdated)
		})
	}

	processAuthReponse(response, callback) {
		const isSuccess = !!response.success
		let error = undefined
		if (response.error) {
			error = response.error
		} else {
			error = 'unknown error'
		}
		this.isLoggedIn = isSuccess
		if (isSuccess) {
			this.user = response.user
			localStorage.setItem('sessionKey', response.sessionKey)
		} else {
			this.user = null
			localStorage.removeItem('sessionKey')
		}
		this.triggerEvent(API.Events.LoginStateChanged)
		if (callback) {
			callback(isSuccess, error)
		}
		if (isSuccess) {
			this.afterAuth()
		}
	}

	login(email, password, callback) {
		this.sendRequest('AUTHENTICATE', { email, password }, response => {
			this.processAuthReponse(response, callback)
		})
	}

	register(email, password, callback) {
		this.sendRequest('REGISTER', { email, password }, response => {
			this.processAuthReponse(response, callback)
		})
	}

	logout() {
		this.sendRequest('LOGOUT', {}, response => {
			this.clearState()
			localStorage.removeItem('sessionKey')
			this.triggerEvent(API.Events.LoginStateChanged)
		})
	}

}

API.Events = {
	LoginStateChanged: 1,
	DashboardsUpdated: 2,
	ConnectionStateChanged: 3
}

API.ConnectionStates = {
	Offline: 0,
	Connecting: 1,
	Authenticating: 2,
	Online: 3
}
