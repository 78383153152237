
import React from 'react'
import API from '../api'
import { BaseView } from '../common-ui'

import {
	Card,
	Elevation,
	Icon,
	Intent,
	FormGroup,
	InputGroup,
	Button,
	Tag,
	Menu,
	MenuItem,
	MenuDivider,
	Popover,
	Position
} from "@blueprintjs/core"

import AceEditor from 'react-ace'
import brace from 'brace'
import 'brace/mode/json'
import 'brace/theme/tomorrow'

export class EditDashboard extends BaseView {

	constructor(props) {
		super(props)
		this.state = { dashboard: null }
		this.codeEditorRef = React.createRef()
	}

	viewId() {
		return 'EditDashboard';
	}

	componentDidMount() {
		this.getDashboard()
		API.shared.addEventListener(this, API.Events.DashboardsUpdated, (data) => {
			this.getDashboard()
			this.forceUpdate()
		})
	}

	get aceEditor() {
		if (this.codeEditorRef.current) {
			return this.codeEditorRef.current.editor
		}
		return null
	}

	componentDidUpdate() {
		const editor = this.aceEditor
		if (editor) {
			editor.resize()
		}
	}

	componentWillUnmount() {
		API.shared.removeEventListener(this)
	}

	getDashboard() {
		if (!this.state.dashboard) {
			for (const d of API.shared.dashboards) {
				if (d.id == this.props.match.params.id) {
					this.setState({ dashboard: d })
					return
				}
			}
		}
	}

	saveChanges(event) {
		API.shared.sendRequest('EDIT-DASHBOARD', { id: this.props.match.params.id, data: this.getCode() }, response => {
			console.log('r', response)
		})
	}

	getCode() {
		return this.aceEditor.getValue()
	}

	setCode(code) {
		this.aceEditor.setValue(code)
	}

	formattedCode(val) {
		return JSON.stringify(JSON.parse(val), null, 2)
	}

	reformatCode(event) {
		let val = this.getCode()
		val = this.formattedCode(val)
		this.setCode(val)
	}

	linkAgent(event) {
		let activationCode = prompt('Agent Authorization Code')
		if (activationCode) {

			activationCode = activationCode.trim()
			activationCode = activationCode.replace(/\-+/g, '')
			activationCode = activationCode.substr(0, 3) + '-' + activationCode.substr(3, 3)
			activationCode = activationCode.toUpperCase()

			API.shared.sendRequest('LINK-AGENT', { id: this.props.match.params.id, code: activationCode }, response => {
				console.log('r', response)
			})
		}
	}

	unlinkAgent(event, agent) {
		if (confirm('Are you sure you want to unauthorize this agent from this dashboard?')) {
			API.shared.sendRequest('UNLINK-AGENT', { id: this.props.match.params.id, agentId: agent.id }, response => {
				console.log('r', response)
			})
		}
	}

	renameAgent(event, agent) {
		console.log({ agent })
		let newName = prompt('Rename Agent...', (agent.alias ? agent.alias : ''))
		if (newName === null) {
			return
		}
		newName = newName.trim()
		if (newName.length == 0) {
			newName = null
		}
		API.shared.sendRequest('ALIAS-AGENT', { dashboardId: this.props.match.params.id, agentId: agent.id, alias: newName }, response => {
			agent.alias = newName
			this.forceUpdate()
			console.log('r', response)
		})

	}

	deleteDashboard(event) {
		if (confirm('Are you sure you want to delete this dashboard?')) {
			API.shared.sendRequest('DELETE-DASHBOARD', { id: this.props.match.params.id }, response => {
				console.log('r', response)
				this.props.history.push('/dashboards/')
			})
		}
	}

	renderView() {
		if (!this.state.dashboard) {
			return <span>loading</span>
		}
		const data = this.formattedCode(this.state.dashboard.data)
		return (
			<React.Fragment>
				<AceEditor
					ref={this.codeEditorRef}
					mode="json"
					value={data}
					theme="tomorrow"
					name="editorContainer"
				/>
				<div id="SidePanel">
					<strong>{this.state.dashboard.title}</strong><br /><br />
					<Button icon="floppy-disk" text="Save Changes" intent={Intent.PRIMARY} onClick={e => this.saveChanges(e)} />
					<Button icon="manually-entered-data" text="Reformat Code" onClick={e => this.reformatCode(e)} />
					<Button icon="trash" text="Delete Dashboard" intent={Intent.DANGER} onClick={e => this.deleteDashboard(e)} />
					<div className="AgentList">
						<h3>Authorized Agents</h3>
						{this.state.dashboard.agents.map(agent => <AgentListElement agent={agent} onUnlinkButton={e => this.unlinkAgent(e, agent)} onRenameButton={e => this.renameAgent(e, agent)} />)}
						<Button icon="plus" text="Authorize New Agent" intent={Intent.SUCCESS} onClick={e => this.linkAgent(e)} />
					</div>
				</div>
			</React.Fragment>
		)
	}

}

function AgentListElement(props) {
	const agentMenu = (
		<Menu>
			<MenuItem icon="remove" text="Remove Agent" onClick={e => props.onUnlinkButton(e)} />
			<MenuItem icon="edit" text="Rename..." onClick={e => props.onRenameButton(e)} />
		</Menu>
	)
	const a = props.agent
	const env = a.environment
	let agentName = null
	if (env) {
		if (env.native) {
			if (env.native.device) {
				if (env.native.device.name) {
					agentName = env.native.device.name
				} else if (env.native.device.model) {
					agentName = env.native.device.model
				}
			}
		}
		if (!agentName && env.browser) {
			agentName = 'Browser'
		}
		if (!agentName) {
			agentName = 'Unknown Agent #' + a.id
		}
		if (a.alias) {
			agentName = a.alias
		}
		if (env.agent && env.agent.version) {
			agentName += ' (' + env.agent.version + ')'
		}
	}
	return (
		<div className="Agent">
			<Icon icon="mobile-phone" /> { agentName}
			<div className="Options">
				<Popover content={agentMenu}>
					<Icon icon="more" />
				</Popover>
			</div>
		</div>
	)
}