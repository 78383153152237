
import React from 'react'
import { Link } from 'react-router-dom'
import API from '../api'
import { BaseView } from '../common-ui'

import {
	Card,
	Elevation,
	Icon,
	Intent,
	FormGroup,
	InputGroup,
	Button
} from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

export class Login extends BaseView {

	constructor(props) {
		super(props)
		this.state = { busy: false, creatingAccount: false }
		
		this.emailInputRef = React.createRef()
		this.passwordInputRef = React.createRef()
		this.passwordConfirmInputRef = React.createRef()
	}

	viewId() {
		return 'Login';
	}

	tryLogin(event) {
		if(this.state.busy) {
			return false
		}

		this.setState({ busy: true })

		API.shared.login(this.emailInputRef.current.value, this.passwordInputRef.current.value, (success, error) => {
			if(!success) {
				alert('Err: ' + error)
			}
			this.setState({ busy: false })
		})

		event.preventDefault()
		return false
	}

	tryCreateAccount(event) {
		if(this.state.busy) {
			return false
		}
		
		event.preventDefault()
		
		if(this.passwordInputRef.current.value != this.passwordConfirmInputRef.current.value) {
			alert('Passwords do not match')
			return false
		}

		this.setState({ busy: true })

		API.shared.register(this.emailInputRef.current.value, this.passwordInputRef.current.value, (success, error) => {
			if(!success) {
				alert('Err: ' + error)
			}
			this.setState({ busy: false })
		})
		
		return false
	}

	setCreatingAccount(event, isCreating) {
		event.preventDefault()
		this.setState({ creatingAccount: isCreating })
		return false
	}

	renderView() {
		return (
			<div className="LoginPanel">

				{ !this.state.creatingAccount &&
					<form onSubmit={ e => this.tryLogin(e) }>
						<h3>Login</h3>
						<InputGroup disabled={ this.state.busy } inputRef={ this.emailInputRef } leftIcon="envelope" placeholder="E-mail"/>
						<br/>
						<InputGroup disabled={ this.state.busy } inputRef={ this.passwordInputRef } leftIcon="key" placeholder="Password" type="password"/>
						<div className="Buttons">
							<a href="#" onClick={ e => this.setCreatingAccount(e, true) }>Create New Account</a>
							<Button disabled={ this.state.busy } icon="unlock" text={ this.state.busy ? "Please Wait..." : "Login" } type="submit"/>
						</div>
					</form>
				}

				{ this.state.creatingAccount &&
					<form onSubmit={ e => this.tryCreateAccount(e) }>
						<h3>Create Account</h3>
						<InputGroup disabled={ this.state.busy } inputRef={ this.emailInputRef } leftIcon="envelope" placeholder="E-mail"/>
						<br/>
						<InputGroup disabled={ this.state.busy } inputRef={ this.passwordInputRef } leftIcon="key" placeholder="Password" type="password"/>
						<br/>
						<InputGroup disabled={ this.state.busy } inputRef={ this.passwordConfirmInputRef } leftIcon="key" placeholder="Confirm Password" type="password"/>
						<div className="Buttons">
							<a href="#" onClick={ e => this.setCreatingAccount(e, false) }>Already have account?</a>
							<Button disabled={ this.state.busy } icon="unlock" text={ this.state.busy ? "Please Wait..." : "Create Account" } type="submit"/>
						</div>
					</form>
				}

			</div>
		)
	}

}
