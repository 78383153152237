
import React from 'react'

export class BaseView extends React.Component {

	viewId() {
		return 'Section';
	}

	renderView() {
		return (
			<React.Fragment>
				...
			</React.Fragment>
		)
	}

	render() {
		return (
			<div id={ this.viewId() } className="BaseView">
				{ this.renderView() }
			</div>
		)
	}

}
