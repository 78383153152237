
import React from 'react'
import { Link } from 'react-router-dom'
import API from '../api'
import { BaseView } from '../common-ui'

import {
	Card,
	Elevation,
	Icon,
	Intent
} from "@blueprintjs/core"
import { IconNames } from "@blueprintjs/icons"

export class Dashboards extends BaseView {

	constructor(props) {
		super(props)
	}

	viewId() {
		return 'Dashboards';
	}

	componentDidMount() {
		API.shared.addEventListener(this, API.Events.DashboardsUpdated, (data) => {
			this.forceUpdate()
		})
	}

	componentWillUnmount() {
		API.shared.removeEventListener(this)
	}

	createNew() {
		const title = prompt('title')
		if(title) {
			API.shared.sendRequest('CREATE-DASHBOARD', { title }, response => {
				this.props.history.push('/dashboards/' + response.id)
			})
		}
	}

	renderView() {
		return (
			<div className="ButtonPanelList">
				{ API.shared.dashboards.map(d => <ButtonCard key={ d.id } id={ d.id } title={ d.title } devices={ d.agents.length }/>) }
				<ButtonCard createNew title="Create New" key="new" onClick={ _ => this.createNew() }/>
			</div>
		)
	}

}

function ButtonCard(props) {
	let base = (
		<Card interactive={ true } elevation={ Elevation.TWO }>
			<div className="Container">
				<div className="Icon">
					<Icon icon={ props.createNew ? IconNames.ADD : IconNames.DOCUMENT } iconSize={ 45 } intent={ Intent.PRIMARY }/>
				</div>
				<div className="Title">{ props.title }</div>
				{ props.devices !== undefined && <div className="Info">{ props.devices } devices</div> }
			</div>
		</Card>
	)
	if(props.onClick) {
		base = (
			<a href="#" onClick={ e => { props.onClick(); e.preventDefault(); return false; } }>
				{ base }
			</a>
		)
	} else {
		base = (
			<Link to={ '/dashboards/' + props.id }>
				{ base }
			</Link>
		)
	}
	return (
		<div className="ButtonCard">
			{ base }
		</div>
	)
	
}